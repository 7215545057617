import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  initialize() {
    const toast = new bootstrap.Toast(this.element)
    toast.show()

    // Remove the toast from the DOM once it is hidden
    this.element.addEventListener('hidden.bs.toast', () => {
      this.element.remove()
    })

    const exportJobId = this.element.dataset.exportJobId
    if (exportJobId) {
      checkExportStatus(exportJobId)
    }
  }
}

function checkExportStatus(exportJobId) {
  const intervalId = setInterval(() => {
    fetch(`/portal/export_jobs/${exportJobId}/status`, {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      if (data.status != 'pending') {
        clearInterval(intervalId)
        handleExportResult(exportJobId, data)
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error)
    })
  }, 5000) // Poll every 5 seconds
}

function handleExportResult(exportJobId, data) {
  fetch(`/portal/export_jobs/${exportJobId}/notify_${data.status}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/vnd.turbo-stream.html'
    }
  })
  .then(response => response.text())
  .then(html => {
    Turbo.renderStreamMessage(html)
    if (data.status === 'completed') {
      window.location = `/portal/files/download/${data.file_path}` // Redirect to the download URL
    }
  })
  .catch(error => {
    console.error('There was a problem with the fetch operation:', error)
  })
}