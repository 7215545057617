import { Controller } from "@hotwired/stimulus"
import { AsYouType, format } from "libphonenumber-js"
import * as bootstrap from "bootstrap"
import moment from 'moment'

export default class extends Controller {
  connect() {
    theme_customizer.init()
    document.querySelector('html').classList.remove('initializing')
    document.querySelector('html').classList.remove('sidebar-enable')
    // document.addEventListener("turbo:load", function() {
    //   Turbo.StreamActions.redirect = function() {
    //     Turbo.visit(this.target)
    //   }
    //   Turbo.StreamActions.redirect_new_tab = function() {
    //     window.open(this.target, '_blank')
    //   }
    // })
  }
}

// Initialize tooltips
window.initializeTooltips = (context) => {
  [].slice.call(context.querySelectorAll('[data-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

// Formats phone numbers as they are entered
window.formatPhoneNumber = (input) => {
  // Remove all spaces from string for easier analysis
  let value = input.value.toString().replace(/\s/g, '')

  // If the value has the format of a phone number with parens AND ends with parens, remove them
  if (value.includes('(') && value.endsWith(')')) {
    let new_value = value.replace(/\(|\)/g,'')
    // If the value is 4 characters long and starts with a 1, keep the space after the 1 (when backspacing)
    if (new_value.length === 4 && new_value.startsWith("1")) {
      input.value = new_value.charAt(0) + " " + new_value.slice(1)
    // Otherwise, set the value to the new value
    } else {
      input.value = new_value
    }
  // Otherwise, apply the AsYouType formatter
  } else {
    input.value = new AsYouType('US').input(value)
    // If the value ends with a paren, ensure there is always a space after it
    if (input.value.endsWith(')')) {
      input.value = input.value + ' '
    }
  }
}

window.initializeDateRangePicker = (input, callback) => {
  if (input == null) { return }

  moment.updateLocale('en', {
    week: {
      dow: 1 // Monday is the first day of the week
    }
  })
  
  var $start_date = $(input).parent().find('input[name="start_date"]')
  var $end_date = $(input).parent().find('input[name="end_date"]')
  
  var presets = $(input).data('presets') || []
  var max_date = $(input).data('maxDate') ? moment($(input).data('maxDate')) : undefined

  var ranges = {
    'Week to Date': presets.includes('week_to_date') ? [moment().startOf('week'), moment()] : undefined,
    'This Week': presets.includes('this_week') ? [moment().startOf('week'), moment().endOf('week')] : undefined,
    'Last Week': presets.includes('last_week') ? [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] : undefined,
    'Next Week': presets.includes('next_week') ? [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week')] : undefined,
    'Month to Date': presets.includes('month_to_date') ? [moment().startOf('month'), moment()] : undefined,
    'This Month': presets.includes('this_month') ? [moment().startOf('month'), moment().endOf('month')] : undefined,
    'Last Month': presets.includes('last_month') ? [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] : undefined,
    'Next Month': presets.includes('next_month') ? [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')] : undefined,
    'Year to Date': presets.includes('year_to_date') ? [moment().startOf('year'), moment()] : undefined,
    'This Year': presets.includes('this_year') ? [moment().startOf('year'), moment().endOf('year')] : undefined,
    'Last Year': presets.includes('last_year') ? [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')] : undefined,
    'Next Year': presets.includes('next_year') ? [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year')] : undefined
  }

  function firstValidRange(ranges) {
    for (var range in ranges) {
      if (ranges[range] !== undefined && ranges[range].length === 2) {
        return ranges[range]
      }
    }
    return null
  }

  function triggerCallbacks(start, end) {
    $start_date.val(start.format('YYYY-MM-DD'))
    $end_date.val(end.format('YYYY-MM-DD'))
    if (callback) callback()
  }

  var $picker = $(input).daterangepicker({
    autoUpdateInput: true,
    linkedCalendars: true,
    startDate: presets.length ? firstValidRange(ranges)[0] : moment().subtract(1, 'day').startOf('day'),
    endDate: presets.length ? firstValidRange(ranges)[1] : moment().subtract(1, 'day').endOf('day'),
    maxDate: max_date,
    template: '<div class="daterangepicker">' +
                '<div class="drp-contents">' + // Added custom wrapper div for css targeting
                  '<div class="ranges"></div>' +
                  '<div class="drp-calendar left">' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                  '</div>' +
                  '<div class="drp-calendar right">' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                  '</div>' +
                  '<div class="drp-buttons">' +
                    '<button class="cancelBtn" type="button"></button>' +
                    '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
                  '</div>' +
                '</div>' +
              '</div>',
    opens: 'left',
    drops: 'auto',
    alwaysShowCalendars: true,
    buttonClasses: 'btn',
    cancelButtonClasses: 'btn-light',
    applyButtonClasses: 'btn-primary',
    ranges: presets.length ? ranges : undefined,
    locale: {
      format: 'MMM D, YYYY',
      firstDay: 1,
      monthNames: moment.months()
    }
  }, (start, end, label) => {
    triggerCallbacks(start, end)
  }).on('show.daterangepicker', function() {
    $(this).addClass('datepicker-open')
  }).on('hide.daterangepicker', function() {
    $(this).removeClass('datepicker-open')
  })

  // Manually execute the callback to simulate the apply action
  var start = $picker.data('daterangepicker').startDate
  var end = $picker.data('daterangepicker').endDate
  triggerCallbacks(start, end)
}

// Initializes special select2 inputs
window.initializeSpecialSelect2 = ({
  $element = undefined,
  selectionLimit = -1,
  placeholder = undefined,
  matcher = undefined,
  emptyContent = "No results found",
  initCallback = undefined,
  selectionCallback = undefined,
  clearCallback = undefined
} = {}) => {
  if ($element.length > 0) {
    $element.select2({
      maximumSelectionLength: selectionLimit,
      allowClear: true,
      placeholder: placeholder,
      language: { noResults: () => emptyContent },
      templateResult: specialTemplate,
      matcher: matcher,
      escapeMarkup: (markup) => { return markup }
    }).on('select2:opening', (e) => {
      if ($(e.target).next().find('.select2-search__field').val() === '') {
        e.preventDefault()
      }
    }).on('select2:selecting', (e) => {
      if (selectionCallback != undefined) {
        selectionCallback(e)
      }
    }).on('select2:select', (e) => {
      styleSpecialSelect($element, selectionLimit)
    }).on('select2:unselect', (e) => {
      styleSpecialSelect($element, selectionLimit)
    }).on('select2:clear', (e) => {
      if (clearCallback != undefined) {
        clearCallback(e)
      }
    })

    styleSpecialSelect($element, selectionLimit)

    $element.next('.select2-container').on('input', '.select2-search__field', (e) => {
      if (e.target.value === '') {
        $(e.target).parents('.select2-container').prev().select2('close')
      }
    })

    if (initCallback != undefined) {
      initCallback($element)
    }
  }
}

window.styleSpecialSelect = ($element, limit) => {
  $element.next().find('.select2-selection__choice__display').each(function() {
    styleSelection($(this), limit)
  })
}

window.styleSelection = (selection, limit=-1) => {
  var textArray = selection.html().split('||')

  if (limit === 1) {
    selection.parents('.select2-selection__choice').addClass('bg-transparent')
    selection.prev().remove()
    selection.addClass('ps-1')
    selection.html(textArray[0] + "<span class='text-muted ps-2'>" + textArray[1] + "</span>")
    selection.parents('.select2-selection').focus()
  } else {
    var pillLabel = textArray[1] + ' at ' + textArray[0]
    selection.html(textArray[2] ? pillLabel + ' (' + textArray[2] + ')' : pillLabel)
  }
}

// Displays the guest's full name and mobile phone number in the special select2 dropdown
window.specialTemplate = (record) => {
  var textArray = record.text.split('||')
  var record = $(
    "<p style='text-align:left; margin-bottom:0;'>" +
    textArray[0] + "<span style='float:right;'>" + textArray[1] + "</span>" +
    "</p>" +
    "<p style='text-align:left; margin-bottom:0;' class='small' >" +
      ( textArray[2] || '-' ) + "<span style='float:right;'>" + truncate((textArray[3] || '-'), 30) + "</span>" +
    "</p>"
  )
  return record
}

// Ignores formatting when searching phone numbers
window.guestMatcher = (params, data) => {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') { return data }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === 'undefined') { return null }

  // Perorm the custom matching
  if (data.text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').indexOf(params.term.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')) > -1) {
    return $.extend({}, data, true)
  }

  return null // Return `null` if the term should not be displayed
}