import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'dropZone']

  connect() {
    this.dropZone = this.element.querySelector('.drop-zone')
    this.dropZone.addEventListener('click', () => this.inputTarget.click())
    this.dropZone.addEventListener('dragover', this.dragOver.bind(this))
    this.dropZone.addEventListener('dragleave', this.dragLeave.bind(this))
    this.dropZone.addEventListener('drop', this.drop.bind(this))
    this.inputTarget.addEventListener('change', () => this.handleFileUpload(this.inputTarget.files))
    this.handleFileUpload(this.inputTarget.files)
  }

  dragOver(event) {
    event.preventDefault()
    this.dropZone.classList.add('drag-over')
  }

  dragLeave() {
    this.dropZone.classList.remove('drag-over')
  }

  drop(event) {
    event.preventDefault()
    this.dropZone.classList.remove('drag-over')

    if (event.dataTransfer.files.length) {
      this.inputTarget.files = event.dataTransfer.files
      this.handleFileUpload(this.inputTarget.files)
    }
  }

  handleFileUpload(files) {
    if (files.length > 0) {
      this.dropZoneTarget.classList.add('file-added')
      this.dropZoneTarget.querySelector('.drop-zone-icon').innerHTML = `<i class='uil-check'></i>`
      this.dropZoneTarget.querySelector('.drop-zone-text').innerHTML = `${files[0].name}`
    } else {
      this.dropZoneTarget.classList.remove('file-added')
      this.dropZoneTarget.querySelector('.drop-zone-icon').innerHTML = `<i class='uil-paperclip'></i>`
      this.dropZoneTarget.querySelector('.drop-zone-text').innerHTML = `Select or drag and drop the file here`
    }
  }
}