import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.form = document.querySelector('#filter_form')
    this.searchField = this.form.querySelector('#search_field')
    this.dateRangeField = this.form.querySelector('#date_range_field')
    this.exportTrigger = this.form.querySelector('#export_trigger')

    if (this.searchField) {
      this.handleSearch = delay((e) => {
        e.preventDefault()
        this.submitForm(this.form)
        if (e.target.value) {
          e.target.classList.add('has-value')
        } else {
          e.target.classList.remove('has-value')
        }
      }, 500).bind(this)
      this.searchField.addEventListener('keyup', this.handleSearch)
      this.searchField.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault() // Prevent form submission
        }
      })
    }

    if (this.dateRangeField) {
      initializeDateRangePicker(this.dateRangeField, () => {
        this.submitForm(this.form)
      })
    }

    if (this.exportTrigger) {
      $(this.exportTrigger).on('click', (e) => {
        this.submitForm(this.form, '/export')
      })
    }

    $(this.form).find('select').select2({ // select:not(.select2-hidden-accessible)
      minimumResultsForSearch: 1
    }).on('select2:select', (e) => {
      this.submitForm(this.form)
      if (e.target.value) {
        e.target.nextElementSibling.classList.add('has-value')
      } else {
        e.target.nextElementSibling.classList.remove('has-value')
      }
    })

    this.handleListViewRefresh = () => {
      this.submitForm(this.form)
    }
    document.addEventListener('listview:refresh', this.handleListViewRefresh)

    initializeTooltips(this.element)
  }

  disconnect() {
    if (this.searchField) {
      this.searchField.removeEventListener('keyup', this.handleSearch)
    }

    $(this.form).find('select').select2('destroy')
    $(this.form).find('select').off('select2:select')

    document.removeEventListener('listview:refresh', this.handleListViewRefresh)
  }

  submitForm(form, nestedPath = '') {
    const url = `${form.action}${nestedPath}?${new URLSearchParams(new FormData(form))}`
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .then(() => {
      document.dispatchEvent(new CustomEvent('listview:refreshed'))
      setTimeout(() => {
        initializeTooltips($("[data-controller=listview]")[0])
      }, 500)
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error)
    })
  }

  addToWatchlist() {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('listview:refresh'))
    }, 500)
  }

  removeFromWatchlist() {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('listview:refresh'))
    }, 500)
  }

}