import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const importJobId = this.element.dataset.importJobId
    const status = this.element.dataset.status

    if (importJobId && status === 'pending') {
      setTimeout(() => {
        fetch(`/portal/import_jobs/${importJobId}/status`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            'Accept': 'text/vnd.turbo-stream.html'
          }
        })
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error)
        })
      }, 1000)
    }
  }

  handleFileUpload() {
    const submitButton = this.element.querySelector('input[type="submit"]')
    const fileField = this.element.querySelector('input[type="file"]')

    if (fileField.value.length > 0) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  }
}