import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    this.tabTargets.forEach((tab) => {
      tab.addEventListener("click", this.activateTab.bind(this))
    })
  }

  activateTab(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute("href").substring(1)
    const targetElement = document.getElementById(targetId)

    if (targetElement) {
      const tab = new bootstrap.Tab(event.currentTarget)
      tab.show()
    }
  }
}