window.delay = (fn, ms) => {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

window.truncate = (str, n) => {
  return (str.length > n) ? str.slice(0, n-1).trim() + '&hellip;' : str
}

window.isNumeric = (num) => {
  return !isNaN(num)
}

window.observer = (selector, onElementAdded) => {
  // Create a new instance of MutationObserver
  new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          // Check if the added node is an element and has the specific class
          if (node.nodeType === Node.ELEMENT_NODE && node.querySelector(selector).length > 0) {
            onElementAdded(node)
          }
        })
      }
    })
  })
}

// window.observeElementChanges = (targetSelector, className, observeType, callback) => {
//   const observer = new MutationObserver((mutations) => {
//     mutations.forEach((mutation) => {
//       let nodes
//       if (observeType === 'additions' && mutation.type === 'childList') {
//         nodes = mutation.addedNodes
//       } else if (observeType === 'removals' && mutation.type === 'childList') {
//         nodes = mutation.removedNodes
//       }

//       if (nodes) {
//         nodes.forEach((node) => {
//           if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains(className)) {
//             callback(node)
//           }
//         })
//       }
//     })
//   })

//   const config = (observeType === 'additions' || observeType === 'removals') ? { childList: true, subtree: true } : {}

//   const targetElement = document.querySelector(targetSelector)
//   if (targetElement) {
//     observer.observe(targetElement, config)
//   } else {
//     console.warn(`No element found with the selector "${targetSelector}"`)
//   }

//   return observer
// }

// // Usage example for observing removals
// const observerRemovals = observeElementChanges('body', 'your-specific-class', 'removals', (element) => {
//   console.log('Element removed:', element)
// })