import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.querySelectorAll(".access-group-note").forEach(function(note) {
      const height = note.scrollHeight;
      if (height > 132) {
        note.classList.add('collapse')
      }
    });
  }
}