import { Controller } from "@hotwired/stimulus"
import 'select2'

// TODO: clean up this controller
// https://mrcodebot.com/turbolinks-calls-stimulus-connect-twice/

export default class extends Controller {
  connect() {
    var select2_count = $(this.element).find('.select2-container').length

    // Wait until turbo adds the new elements to the DOM
    if (select2_count == 0) {
      initializeSpecialSelect2({
        $element: $('#guest.select2-special'),
        placeholder: "Find or invite a guest",
        matcher: guestMatcher,
        emptyContent: guestEmptyActions,
        initCallback: guestInitCallback,
        selectionCallback: selectionCallback
      })
    }

    // Set focus to the first input
    $('#guest.select2-special').focus()

    // Initialize tooltips
    initializeTooltips(this.element)

    // Set up the refresh event
    document.addEventListener('watchlist:refresh', this.refresh.bind(this, 'watchlist'))
  }

  disconnect() {
    document.removeEventListener('watchlist:refresh', this.refresh)
  }

  refresh(frame) {
    Turbo.visit(document.location.pathname, { frame: frame })
  }
}

// Adds the action buttons to the special select2 dropdown
function guestEmptyActions () {
  var $footer = $("<div class='select2-empty-actions row gx-2'>")
  $footer.append("<div class='col'><a href='/portal/guests/new' data-turbo-frame='modal' class='btn btn-sm btn-light w-100 px-0'>Invite to PX</a></div>")
  // $footer.append("<div class='col'><a href='/portal/one_time_passes/new' data-turbo-frame='modal' class='btn btn-sm btn-light w-100 px-0'>Send one-time pass</a></div>")

  var buttons = $footer.find('a')
  var searchText = $('.select2-search__field:focus').val()
    
  if (/^[0-9+(]/.test(searchText)) { // If a number is entered, add mobile phone params to links
    var mobile_phone = searchText.replace(/\+/g, '%2B').replace(/[^\d%2B]+/g, '')
    buttons.each(function () {
      $(this).attr('href', $(this).attr('href') + '?mobile_phone=' + mobile_phone)
    })
  } else { // Otherwise, add name params to links
    var first_name = searchText.split(' ')[0]
    var last_name = searchText.split(' ')[1]
    buttons.each(function () {
      $(this).attr('href', $(this).attr('href') + '?first_name=' + first_name)
      if (last_name != undefined && last_name != '') {
        $(this).attr('href', $(this).attr('href') + '&last_name=' + last_name)
      }
    })
  }

  $footer.on('click', function (e) {
    setTimeout(() => {
      $('#guest.select2-special').select2('close')
    })
  })
  return $footer
}

function guestInitCallback ($element) {
  // Search callbacks
  $element.next('.select2-container').find('.select2-search__field').on('input', (e) => {
    // If a number is entered, format it as a phone number
    if (/^[0-9+(]/.test(e.target.value)) {
      formatPhoneNumber(e.target)
    }
  })

  // Keypress callbacks
  $element.next('.select2-container').find('.select2-search__field').on('keyup', (e) => {
    // If the ENTER key is pressed while the 'Invite new guest' button is visible, select the button
    if (e.which == 13 && $('.select2-empty-actions').length > 0) {
      $('.select2-empty-actions a').get(0).click()
    }
  })
}

function selectionCallback (e) {
  e.preventDefault()
  var link = $('#' + e.params.args.data.id)
  link.get(0).click()
  $(e.target).select2('close')
}