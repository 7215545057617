import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  openReservationLink(event) {
    event.preventDefault()

    this.trackEvent(`Began booking at ${event.target.dataset.restaurantNameWithLabel}`, {
      reservation_link_id: event.target.dataset.reservationLinkId,
      reservation_link_button_label: event.target.dataset.reservationLinkButtonLabel,
      restaurant_id: event.target.dataset.restaurantId,
      restaurant_name_with_label: event.target.dataset.restaurantNameWithLabel
    })

    let widget = document.getElementById('ot-reservation-widget')
    let restaurant_picker = widget.querySelector('.ot-restaurant-picker select')
    let submit_button = widget.querySelector('.ot-button')

    restaurant_picker.value = event.target.dataset.rid
    restaurant_picker.dispatchEvent(new Event('change'))
    
    var original_rest_ref = submit_button.getAttribute('data-ot-restref').replace(/&accessRuleToken=.*$/, "")
    submit_button.setAttribute('data-ot-restref', original_rest_ref + '&accessRuleToken=' + event.target.dataset.accessRuleToken)
    submit_button.click()
  }

  trackEvent(name, details={}) {
    fetch(`/event/create`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        event: {
          name: name,
          details: details
        }
      })
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Failed to track event')
      }
    })
    .catch(error => {
      console.error('Error:', error)
    })
  }
  
}