import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    // Initialize modal with Bootstrap
    this.modal = new bootstrap.Modal(this.element)

    // Register the change event listener
    this.element.addEventListener('change', resetFieldState)
  }

  disconnect() {
    this.element.removeEventListener('change', resetFieldState)
  }
  
  open() {
    // Open the modal
    if (!this.modal._isShown) {
      this.modal.show()

      // Set focus on the first input when first opening the modal
      setTimeout(function() {
        $(this.modal).find('input, textarea, select').not('[type=hidden]').first().focus()
      }, 500)
    }

    // TODO relocate these initializers
    setTimeout(function() {
      initializeSpecialSelect2({
        $element: $('#one_time_pass_guest_id.select2-special'),
        selectionLimit: 1,
        placeholder: "Select or invite a guest",
        matcher: guestMatcher,
        emptyContent: guestEmptyActions,
        initCallback: guestInitCallback,
        clearCallback: guestClearCallback
      })

      initializeSpecialSelect2({
        $element: $('#access_group_reservation_link_ids.select2-special'),
        placeholder: "Select one or more links",
        emptyContent: "No links found"
      })
    })

    // Initialize Select2 and register the select2:select event listener
    this.initializeSelect2()

    // Initialize the Mobile Phone input with libphonenumber-js
    const input = document.querySelector("#guest_mobile_phone")
    if(input != null) {
      formatPhoneNumber(input)
      input.addEventListener("input", () => {
        formatPhoneNumber(input)
      })
    }

    // Initialize tooltips
    initializeTooltips(this.element)

    // Initialize datepickers
    $(this.element).find('[data-toggle="datepicker"]').datepicker({ autoclose: true })

    // Initialize the nested delete link behavior
    document.querySelectorAll('.remove-link').forEach(function(link) {
      link.addEventListener('click', function(e) {
        e.preventDefault()
  
        var nested_fields = this.closest('.nested-fields')
        nested_fields.querySelector("input[name*='_discard']").value = 1
        nested_fields.classList.add('d-none')

        var one_time_passes = this.closest('.one-time-passes')
        if ($(one_time_passes).find('.nested-fields:not(.d-none)').length == 0) {
          $(one_time_passes).addClass('d-none')
        }
      })
    })

    // Initialize geocomplete
    // TODO CLEAN UP
    var $geo_wrapper = $('.address-input')
    var $geo_field = $("[data-geo='formatted_address']")
    var $geo_url = $("[data-geo='url']")
    
    $geo_field.geocomplete({
      types: ['address'],
      // country: 'us',
      details: '.geo-details',
      detailsAttribute: 'data-geo',
      map: '.map',
      mapOptions: {
        disableDefaultUI: true,
        draggable: false,
        keyboardShortcuts: false,
        clickableIcons: false,
        zoom: 15,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [ { visibility: 'off' } ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [ { visibility: 'off' } ]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [ { visibility: 'off' } ]
          }
        ]
      }
    }).on('geocode:result', function(event, result) {
      $(this).geocomplete('map').setZoom(15)
      showMap(true)
      this.dataset['geocoded'] = true
      setTimeout(() => {
        document.querySelectorAll('.map a').forEach(a => {
          a.tabIndex = -1
        })
      }, 500)
      if (result['url']) {
        $(this).val($(this).data().plugin_geocomplete.data.formatted_address)
        setNextFocus(this)
        $geo_url.removeAttr('disabled')
      }
    }).on('keypress', function(event, result) {
      if (event.key === 'Enter' || event.key === 'Tab') {
        $(this).val($(this).data().plugin_geocomplete.lastInputVal)
        setNextFocus(this)
      }
      if (this.dataset.geocoded && (event.key.length === 1 && !event.ctrlKey && !event.metaKey)) {
        resetGeocode(this)
      }
    }).on('input', function(event, result) {
      // checkForContent(this)
      if (this.dataset.geocoded) {
        resetGeocode(this)
      }
    })

    $geo_wrapper.on('click', function(event) {
      $geo_field.focus()
    }).on('focus click keyup', function(event, result) {
      if ($geo_field[0].dataset.geocoded) {
        $geo_field.select()
        setTimeout(() => {
          $geo_field.select()
        })
      } else {
        setTimeout(() => {
          showMap(false)
        }, 250)
      }
    })

    if ($geo_url.val() != '') {
      // checkForContent($geo_field[0])
      $geo_field.trigger('geocode')
    }

    const targetContainer = document.querySelector('.address-input')
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('pac-container')) {
            if (!targetContainer.contains(node)) {
              targetContainer.appendChild(node)
              observer.disconnect()
            }
          }
        })
      })
    })
    observer.observe(document.body, { childList: true, subtree: true });

    // function checkForContent(field) {
    //   if (field && field.value.length > 0) {
    //     $(field).addClass('has-content')
    //   } else {
    //     $(field).removeClass('has-content')
    //   }
    // }

    function isVisibleAndFocusable(element) {
      return element.offsetWidth > 0 && element.offsetHeight > 0 && getComputedStyle(element).visibility !== 'hidden'
    }

    function setNextFocus(field) {
      var focusableElements = Array.from(document.querySelectorAll('input, textarea, select, button, a[href], [tabindex]'))
      focusableElements = focusableElements.filter(el => isVisibleAndFocusable(el) && (el.tabIndex >= 0))
      const currentIndex = focusableElements.indexOf(field)
      let nextIndex = currentIndex + 1
      if (nextIndex < focusableElements.length) {
        focusableElements[nextIndex].focus()
      }
    }

    function showMap (show) {
      if (show) {
        document.querySelector('.pac-container').classList.add('d-none')
        document.querySelector('.map').classList.remove('d-none')
      } else {
        document.querySelector('.pac-container').classList.remove('d-none')
        document.querySelector('.map').classList.add('d-none')
      }
    }

    function resetGeocode (field) {
      delete field.dataset.geocoded
      $(field).data().plugin_geocomplete.data = null
      $(field).data().plugin_geocomplete.lastInputVal = null
      $geo_url.removeAttr('disabled')
      document.querySelectorAll('.geo-details input').forEach(input => {
        input.value = ''
      })
      setTimeout(() => {
        showMap(false)
      }, 250)
    }
  }

  close(event) {
    // Close the modal if form submission was successful
    if (event.detail.success) {
      this.modal.hide()
    }
  }

  dismiss() {
    // Reset the modal
    this.element.firstChild.firstChild.innerHTML = ""
    this.element.firstChild.firstChild.removeAttribute("src")
    this.element.firstChild.firstChild.removeAttribute("complete")
  }

  initializeSelect2() {
    $(this.element).find('.select2').select2({
      minimumResultsForSearch: -1
    }).on('select2:select', function (e) {
      // Send change events to the select element
      var event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }
}

function resetFieldState (event) {
  event.target.classList.remove('is-invalid')  
}

// TODO clean up and consolidate wuth dashboard_controller.js
function guestInitCallback ($element) {
  // Add the guest to the dropdown if the guest is not persisted
  var guest_element = $('#one_time_pass_guest_id')
  var mobile_phone_element = $('#one_time_pass_guest_attributes_mobile_phone')
  if (guest_element.val().length == 0 && mobile_phone_element.val() != '') {
    selectNewGuest(mobile_phone_element.val())
  }

  // Search callbacks
  $element.next('.select2-container').find('.select2-search__field').on('input', (e) => {
    // If a number is entered, format it as a phone number
    if (/^[0-9+(]/.test(e.target.value)) {
      formatPhoneNumber(e.target)
    }
  })

  // Keypress callbacks
  $element.next('.select2-container').find('.select2-search__field').on('keyup', (e) => {
    // If the ENTER key is pressed while the 'Invite new guest' button is visible, select the button
    if (e.which == 13 && $('.select2-empty-actions').length > 0) {
      $('.select2-empty-actions a').get(0).click()
    }
  })
}

function guestClearCallback ($element) {
  // Reset the mobile phone input
  var mobile_phone_element = $('#one_time_pass_guest_attributes_mobile_phone')
  mobile_phone_element.attr('disabled', 'disabled')
  mobile_phone_element.val(null)

  // Remove the 'new guest' option from the dropdown
  var guest_element = $('#one_time_pass_guest_id')
  guest_element.find("option:contains('New Guest')").remove()
}

// Adds the action buttons to the special select2 dropdown
function guestEmptyActions () {
  var search_text = $('.select2-search__field').val()

  if (/^[0-9+(]/.test(search_text)) {
    var $footer = $("<div class='select2-empty-actions row gx-2'>")
    $footer.append("<div class='col'><a href='javascript:void(0)' class='btn btn-sm btn-light w-100 px-0'>Invite new guest</a></div>")
    $footer.on('click', function (e) {
      selectNewGuest(search_text)
    })
    return $footer
  } else {
    return "No guests found"
  }
}

function selectNewGuest (value) {
  var select_element = $('#one_time_pass_guest_id')
  select_element.append("<option selected='selected'>New Guest," + value + "</option>")
  select_element.trigger('change')
  select_element.select2('close')
  
  var mobile_phone_element = $('#one_time_pass_guest_attributes_mobile_phone')
  mobile_phone_element.removeAttr('disabled')
  mobile_phone_element.val(value)

  setTimeout(function() {
    styleSelection(select_element.next().find('.select2-selection__choice__display'), 1)
  })
}