import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var controller = this

    // Define elements
    controller.flowStepOne = document.querySelector('.registration-step-one')
    controller.flowStepTwo = document.querySelector('.registration-step-two')
    controller.checkboxes = controller.flowStepOne.querySelectorAll('[id*=guest_agreed_to_]')
    controller.btnStepTwo = controller.flowStepOne.querySelector('.btn-step-two')
    controller.btnStepOne = controller.flowStepTwo.querySelector('.btn-step-one')

    // Set up consent listeners
    controller.checkConsent = () => {
      var allChecked = Array.from(controller.checkboxes).every(checkbox => checkbox.checked)
      if (allChecked) {
        controller.btnStepTwo.disabled = false
      } else {
        controller.btnStepTwo.disabled = true
      }
    }
    controller.checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener('change', controller.checkConsent)
    })

    // Set up field change event listener
    controller.element.addEventListener('change', resetFieldState)
    
    // Set up flow nav listeners
    
    //// Go to step #2
    controller.goToStepTwo = () => {
      controller.flowStepOne.classList.add('d-none')
      controller.flowStepTwo.classList.remove('d-none')
    }
    controller.btnStepTwo.addEventListener('click', controller.goToStepTwo)

    //// Go to step #1
    controller.goToStepOne = () => {
      controller.flowStepOne.classList.remove('d-none')
      controller.flowStepTwo.classList.add('d-none')
    }
    controller.btnStepOne.addEventListener('click', controller.goToStepOne)
  }

  disconnect() {
    var controller = this

    // Remove consent listeners
    document.querySelectorAll('[id*=guest_agreed_to_]').forEach(function(checkbox) {
      checkbox.removeEventListener('change', controller.checkConsent)
    })

    // Remove the field change event listener
    controller.element.removeEventListener('change', resetFieldState)

    // Remove flow nav listeners
    controller.btnStepOne.removeEventListener('click', controller.goToStepOne)
    controller.btnStepTwo.removeEventListener('click', controller.goToStepTwo)
  }

}

function resetFieldState (event) {
  event.target.classList.remove('is-invalid')  
}