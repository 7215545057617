import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    initializeTooltips(this.element)
  }

  remove(event) {
    var watched_item = $(event.target).parents('.watched-item')
    var watched_items = watched_item.parents('.watched-items')
    var empty_item = watched_items.find('.empty-item')

    watched_item.remove()
    
    if (watched_items.find('.watched-item').length == 0) {
      empty_item.removeClass('d-none')
    } else {
      empty_item.addClass('d-none')
    }

    document.dispatchEvent(new CustomEvent('watchlist:refresh'))
  }

}