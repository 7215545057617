import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    var controller = this

    // Initialize the Mobile Phone input with libphonenumber-js
    const input = document.querySelector("#mobile_phone")
    if(input != null) {
      formatPhoneNumber(input)
      input.addEventListener("input", () => {
        formatPhoneNumber(input)
      })
    }
  }
}